
export default (context) => {
  return {
    columns: [
      {
        prop: "id",
        label: "ID",
        width: 10,
      },
      {
        prop: "businessNo",
        label: "业务编号",
        width: 15,
      },
      {
        prop: "instrumentName",
        label: "仪器名称",
        width: 15,
      },
      {
        prop: "factoryNo",
        label: "出厂编号",
        width: 15,
      },
      {
        prop: "specificationsType",
        label: "型号规格",
        width: 15,
      },
      {
        prop: "client",
        label: "委托单位",
        width: 15,
      },
      {
        prop: "finalTime",
        label: "完成日期",
        width: 15,
      },
      {
        prop: "operTime",
        label: "最后导出日期",
        width: 15,
      },
    ]
  };
}