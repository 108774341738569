vue
<template>
  <div class="export-model">
    <el-dialog :visible.sync="dialogVisible" class="dialog">
      <div slot="title" class="export_modal_top ">
        <span>{{ "选择导出文件" }}</span>
        <span @click="dialogVisible = false">{{ "×" }}</span>
      </div>
      <div class="download-file-box">
        <div class="table-tools-box">
          <div class="filter-item">
            <span>筛选日期</span>
            <el-date-picker v-model="timeFilter" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="filter-item">
            <button class="search-button" @click="search">搜索</button>
          </div>
          <div class="filter-item export">
            <button class="export-button" @click="downloadSelect">导出</button>
            <button class="export-button" @click="downloadFileByrecordDirBatch_">导出原始数据</button>
          </div>
        </div>
        <div class="download-file-table-box">
          <el-table :data="tableData" style="width: 100%" :border="false" :cell-class-name="tableCellClassName"
            :resizable="false" class="table" height="100%" @selection-change="tableSelectEvent" ref="ttable">
            <el-table-column type="selection" :min-width="5">
            </el-table-column>
            <el-table-column v-for="item in tableConfig.columns" :key="item.prop" :prop="item.prop" :label="item.label"
              :min-width="item.width || 80" align="center" header-align="center" :resizable="false">
            </el-table-column>
            <el-table-column label="操作" align="center" header-align="center" :min-width="30" :resizable="false">
              <template #default="{ row }">
                <div style="display: flex;align-items: center;justify-content: space-around;">
                  <button class="text-button" @click="handler(row)" style="margin: 0 auto;">下载</button>
                  <button class="text-button" @click="downLoadFileByrecordDir_(row)"
                    style="margin: 0 auto;">下载原始数据</button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-bottom">
          <div class="table-pagination">
            <el-pagination background :page-sizes="[10, 20, 50, 100]" v-model:page-size="pageSize"
              layout="total, sizes,prev, pager, next,jumper" :total="dataTotal" @size-change="pageSizeChange"
              @current-change="pageNumChange" v-model:current-page="pageNum">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import getTableConfig from "./tableConfig"
import Table from "@/components/Table";
import { getOrderList } from "@/api/order";
import { download } from "@/utils/common";
import { Message } from "element-ui"
import { downloadPDFZIP, downloadWorld, downloadFileByrecordDir, downloadFileByrecordDirBatch } from "@/api/data"
export default {
  data() {
    return {
      dialogVisible: true, // 对话框可见性
      selectList: [], // 选择列表
      pageSize: 10, // 每页显示数量
      pageNum: 1, // 当前页数
      tableConfig: null, // 表格配置
      timeFilter: "", // 时间筛选
      dataTotal: 0, // 数据总数
      tableData: [], // 表格数据
    }
  },
  components: {
    Table
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.destroy?.();
      }
    },
    pageNum() {
      this.getOrderList();
    },
    pageSize() {
      this.getOrderList();
    }
  },
  props: {
    destroy: {
      type: Function,
    },
  },
  methods: {
    async handler(row) {
      const { id, wordName } = row;
      try {
        const res = await downloadWorld({ orderId: id, fileName: wordName });
        download(wordName, res.data);
        this.search();
      } catch (e) {
        console.log(e);
      }
    },
    getClass(e, scope) {
      const _class = [];
      if (typeof e.class === "function") {
        return e.class(scope);
      }
      if (e.class) _class.push(e.class);
      return _class;
    },
    tableCellClassName(e) {
      if (e.columnIndex === 6 && e.row.state) {
        return e.row.state;
      }
    },
    async getOrderList(params) {
      try {
        const res = await getOrderList({ workStatusList: "3", pageSize: this.pageSize, pageNum: this.pageNum, ...params });
        this.dataTotal = res.total;
        this.tableData = res.rows;
      } catch (e) {
        console.error(e);
      }
    },
    search() {
      const params = {
        finalStartTime: (this.timeFilter && this.timeFilter[0]) || null,
        finalEndTime: (this.timeFilter && this.timeFilter[1]) || null,
      };
      this.getOrderList(params);
    },
    pageSizeChange(e) {
      this.pageSize = e;
    },
    pageNumChange(e) {
      this.pageNum = e;
    },
    tableSelectEvent(e) {
      this.selectList = e;
    },
    downloadSelect() {
      if (!this.selectList || this.selectList.length == 0) {
        Message("当前未选中文件");
        return;
      }
      let arr = [];
      let OrderIDs = [];
      this.selectList.forEach(item => {
        arr.push(item.wordDir);
        OrderIDs.push(item.id);
      });
      downloadPDFZIP({ files: JSON.stringify(arr), orderIds: JSON.stringify(OrderIDs) }).then(res => {
        download("ZIP.zip", res.data);
      }).catch(e => {
        Message("异常");
      });
    },
    downloadFileByrecordDirBatch_() {
      if (!this.selectList || this.selectList.length == 0) {
        Message("当前未选中文件");
        return;
      }
      let arr = [];
      this.selectList.forEach(item => {
        arr.push(item.recordDir)
      });
      downloadFileByrecordDirBatch({ files: JSON.stringify(arr) }).then(res => {
        download("ZIP.zip", res.data);
      }).catch(e => {
        Message("导出异常");
      });
    },
    downLoadFileByrecordDir_: async function (row) {
      try {
        const { recordDir } = row;
        let res = await downloadFileByrecordDir({ recordDir });
        download(recordDir, res.data);
      } catch (error) {

      }
    },
  },
  created() {
    this.getOrderList();
    this.tableConfig = getTableConfig(this);
  },
};
</script>
<style lang="less">
.export_modal_top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:last-child {
    cursor: pointer;
    font-size: 18px;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.el-dialog__body {
  padding: 0 20px;
}

.dialog {
  .el-dialog {
    width: fit-content;
  }

  .el-dialog__headerbtn {
    display: none;
  }
}

.dialog-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
}

.download-file-box {
  min-height: 600px;
  min-width: 1200px;

  .download-file-table-box {
    height: 500px;
    padding: 1px;
    box-sizing: border-box;
  }

  .table-tools-box {
    height: 60px;
    display: flex;
    align-items: center;

    .export {
      color: transparent;
    }

    .export-button {
      height: 30px;
      background-color: #0052d9;
      border-radius: 4px;
      border: none;
      color: #fff;
      height: fit-content;
      min-width: 60px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .table-pagination {
    display: flex;
    justify-content: flex-end;
    height: 60px;
    align-items: center;
  }
}
</style>