<template>
  <div class="data-transmission-view-box">
    <div class="import-box">
      <view-card title="导入原系统数据">
        <div class="content" slot="content">
          <div>
            <span class="tip">请上传原系统导出的表格文件，请勿修改表格内容</span>
          </div>
          <div class="import">
            <span class="import-tip">选择导入类型</span>
            <div class="import-select">
              <el-select v-model="importKey" size="medium">
                <el-option v-for="item in exportOptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <span class="export-tip">导入数据</span>
            <div class="upload-box">
              <el-upload :action="uploadUrl" :auto-upload="false" class="uploader" :headers="headers"
                :file-list="fileList" ref="importUpload" :on-success="uploadSuccess" :multiple="false" :limit="1"
                :on-exceed="exceed" accept=".xlsx">
                <svg-icon icon-class="file-open" :size="2"></svg-icon>
              </el-upload>
              <div class="templateDownload" @click="downloadTemplate">
                <svg-icon icon-class="excel" :size="1.3"></svg-icon>
                <a href="#">模板格式下载</a>
              </div>
            </div>
          </div>
          <div class="button-box">
            <button class="btn-big primary-button" @click="importHandler">导入</button>
          </div>
        </div>
      </view-card>
    </div>
    <div class="export-box">
      <view-card title="导出本系统数据">
        <div class="content" slot="content">
          <div class="item_2">
            <button class="btn-big warning-buttton" @click="exportHandler">导出本系统数据</button>
          </div>
        </div>
      </view-card>
    </div>
  </div>
</template>

<script>
import { downloadFile, getOrderimportInfo, getUserimportInfo } from "@/api/data";
import { download } from "@/utils/common";
import ExportModel from "./exportModel/exportModel";

export default {
  name: "",
  data() {
    return {
      exportOptions: [
        { label: "用户信息", value: "user" },
        { label: "工单信息", value: "order" },
      ],
      headers: {},
      fileList: [],
      importKey: null,
      exportKey: null,
      importFileName: "",
    };
  },
  computed: {
    uploadUrl() {
      return this.importKey === "user" ? "/api/system/user/importData" : "/api/workOrder/importData";
    }
  },
  watch: {
    exportKey: {
      handler(val) {
        if (val) {
          downloadFile({ fileName: val }).then((res) => {
            const fileData = res; // 后端返回的文件流
            const blob = new Blob([fileData]);
            const url = window.URL.createObjectURL(blob); // 创建下载的链接
            const downloadElement = document.createElement("a");
            downloadElement.style.display = "none";
            downloadElement.href = url;
            downloadElement.download = "xxx.word"; // 下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(url); // 释放掉blob对象
          });
        }
      },
    },
  },
  methods: {
    importHandler() {
      if (!this.importKey) {
        this.$message("未选择模板类型");
        return;
      }
      this.$refs.importUpload.submit();
    },
    uploadSuccess(event) {
      if (event.code === 200) {
        this.$modal.msg("文件上传成功");
      } else {
        this.$modal.msgError(event.msg);
      }
    },
    uploadError(event) {
      this.$modal.msg(event);
    },
    exceed() {
      this.$modal.msgWarning("单次只允许上传一个文件");
    },
    exportHandler() {
      ExportModel();
    },
    async downloadTemplate() {
      if (!this.importKey) {
        this.$message("未选择模板类型");
        return;
      }

      let importInfoFunction;

      if (this.importKey === "user") {
        importInfoFunction = getUserimportInfo;
      } else {
        importInfoFunction = getOrderimportInfo;
      }

      try {
        const res = await importInfoFunction();
        download(this.importKey + ".xlsx", res.data);
      } catch (error) {
        console.error("下载模板出错：", error);
        this.$message("下载模板出错");
      }
    }
  },
  created() {
    this.headers = {
      Authorization: "Bearer " + this.$store.getters.token,
    };
  },
};
</script>

<style lang="less">
.data-transmission-view-box {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-flow: column nowrap;
  overflow: auto;

  .import-box {
    height: 500px;

    .content {
      position: relative;

      .tip {
        color: var(--blue);
        font-size: var(--fontSizeNormal);
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 16px;
      }
    }
  }

  .import-box,
  .export-box {
    width: 100%;
    background-color: #ffff;
    border-radius: 10px;

    .content {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      height: 85%;
      gap: 50px;

      &>div {
        text-align: left;
      }

      .item {
        display: flex;
        gap: 10px;
        font-size: var(--fontSizeNormal);
        font-weight: bold;
        width: 600px;
        height: fit-content;

        .upload-box {
          display: flex;
          align-items: start;
          flex-flow: column;
          row-gap: 20px;
          width: fit-content;
        }

        .export-tip {
          line-height: 2;
          width: 120px;
          box-sizing: border-box;
        }

        .svg-icon {
          color: var(--blue);
        }

        .showFileName {
          width: 400px;
          border: 1px solid #c9c9c9;
          height: 100%;
          cursor: pointer;
        }
      }

      .templateDownload {
        text-align: left;
        font-size: var(--fontSizeNormal);
        font-weight: bold;
      }

      .button-box {
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .export-box {
    flex: 1;

    .item {
      justify-content: center;
    }
  }

  .uploader {
    display: flex;
    gap: 10px;
    height: 40px;
    align-items: center;
    position: relative;
    width: 450px;

    .el-upload--text {
      position: absolute;
      right: 0;
    }

    .el-upload-list {
      width: 400px;
      border: 1px solid #c0c4cc;
      height: 36px;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
      border-radius: 4px;

      .el-upload-list__item:first-child {
        margin: 0;
      }
    }
  }

  .btn {
    margin-left: 20px;
  }
}

.item_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.import {
  display: flex;
  align-items: center;
  gap: 10px;
  height: fit-content;
  width: 600px;
  font-size: var(--fontSizeNormal);
  font-weight: bold;

  .import-tip {
    width: 120px;
  }

  .import-select {
    flex: 1;
  }
}
</style>
